import Vue from 'vue';
import VueCustomElement from 'vue-custom-element';
import 'document-register-element/build/document-register-element';
import TheVideo from './TheVideo.vue';

Vue.config.devtools = process.env.NODE_ENV === 'development';
Vue.config.productionTip = false;

Vue.use(VueCustomElement);

Vue.customElement('dtcmedia-video', TheVideo);
