<template>
    <div
        :class="$style.dtcmediaVideoComponent"
        :style="getComponentStyles()"
        ref="component">
        <div
            v-if="modal"
            @click="toggleModal()"
            :class="$style.dtcmediaVideoThumb"
            :style="getThumbStyles()">
        </div>
        <iframe
            v-else
            :class="$style.dtcmediaVideoAbsoluteVideo"
            :src="getVideoSource()"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            frameborder="0"
            height="315px"
            ref="video"
            width="560px">
        </iframe>
        <transition name="fade">
            <div
                v-if="modal && showModal"
                @click="toggleModal()"
                :class="$style.dtcmediaVideoModal">
                <iframe
                    :class="$style.dtcmediaVideoRelativeVideo"
                    :src="getVideoSource()"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    frameborder="0">
                </iframe>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
  name: 'TheVideo',

  props: {
    autoplay: {
      type: Number,
      required: true,
      default: 0,
    },

    loop: {
      type: Number,
      default: 0,
    },

    controls: {
      type: Number,
      required: false,
      default: 0,
    },

    cookies: {
      type: Number,
      required: false,
      default: 0,
    },

    height: {
      type: String,
      required: false,
      default: '100%',
    },

    id: {
      type: String,
      required: true,
    },

    modal: {
      type: Number,
      required: false,
      default: 0,
    },

    mute: {
      type: Number,
      required: false,
      default: 0,
    },

    thumb: {
      type: String,
      required: false,
      default: null,
    },

    width: {
      type: String,
      required: false,
      default: '100%',
    },
  },

  data() {
    return {
      showModal: false,
    };
  },

  mounted() {
    window.addEventListener('resize', this.onResize);

    this.setDimensions();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.setDimensions();
    },

    getVideoSource() {
      return `https://www.youtube${this.cookies === 0 ? '-nocookie' : ''}.com/embed/${this.id}?controls=${this.controls}&autoplay=${this.autoplay}&mute=${this.mute}&loop=${this.loop}${this.loop ? `&playlist=${this.id}` : ''}`;
    },

    getComponentStyles() {
      return {
        height: this.height,
        width: this.width,
      };
    },

    getThumbStyles() {
      return {
        backgroundImage: this.thumb ? `url(${this.thumb})` : `url(https://img.youtube.com/vi/${this.id}/maxresdefault.jpg)`,
      };
    },

    setDimensions() {
      if (!this.modal) {
        const { component } = this.$refs;
        const componentHeight = component.getBoundingClientRect().height;
        const componentWidth = component.getBoundingClientRect().width;
        const { video } = this.$refs;
        const videoHeight = video.getBoundingClientRect().height;
        const videoWidth = video.getBoundingClientRect().width;

        let height = componentHeight;
        let width = componentWidth;

        if ((componentWidth / componentHeight) > (videoWidth / videoHeight)) {
          height = componentWidth * (videoHeight / videoWidth);
        } else {
          width = componentHeight * (videoWidth / videoHeight);
        }

        video.setAttribute('height', height);
        video.setAttribute('width', width);
      }
    },

    toggleModal() {
      if (this.modal) {
        this.showModal = !this.showModal;
      }
    },
  },
};
</script>

<style lang="scss" module>
.dtcmedia-video-component {
    display: inline-block;
    overflow: hidden;
    position: relative;
}

.dtcmedia-video-thumb {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    &::before {
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 50%;
        content: '';
        height: 128px;
        left: calc(50% - 64px);
        position: absolute;
        top: calc(50% - 64px);
        transition: all 150ms linear;
        width: 128px;
    }

    &::after {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgNDQzLjMwNyA0NDMuMzA2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NDMuMzA3IDQ0My4zMDY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cGF0aCBkPSJNNDE1LjkzNCwyMTIuNzk5TDM2Ljc4OCwyLjA5N0MzMi40MTEtMC4zNzcsMjguNjUtMC42NjEsMjUuNTEsMS4yNDJjLTMuMTQsMS45MDItNC43MDgsNS4zMjgtNC43MDgsMTAuMjc2VjQzMS43OCAgIGMwLDQuOTUyLDEuNTY5LDguMzgxLDQuNzA4LDEwLjI4NGMzLjE0LDEuOTAyLDYuOTAxLDEuNjIyLDExLjI3OC0wLjg1NWwzNzkuMTQ2LTIxMC43MDNjNC4zODEtMi40NzgsNi41NzEtNS40MzQsNi41NzEtOC44NTYgICBDNDIyLjUwNSwyMTguMjI0LDQyMC4zMTQsMjE1LjI3NCw0MTUuOTM0LDIxMi43OTl6IiBmaWxsPSIjRkZGRkZGIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        height: 64px;
        left: calc(50% - (32px * 0.8));
        position: absolute;
        top: calc(50% - 32px);
        transition: all 150ms linear;
        width: 64px;
    }

    &:hover {
        &::before,
        &::after {
            transform: scale(1.05);
        }
    }
}

.dtcmedia-video-absolute-video {
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.dtcmedia-video-relative-video {
    height: 50vw;
    width: 90vw;

    @media screen and (min-width: 768px) {
        height: 45vw;
        width: 80vw;
    }
}

.dtcmedia-video-modal {
    align-items: center;
    background: rgba(0, 0, 0, 0.9);
    bottom: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;

    &::after {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNTEycHgiIHZlcnNpb249IjEuMSIgaGVpZ2h0PSI1MTJweCIgdmlld0JveD0iMCAwIDY0IDY0IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA2NCA2NCI+CiAgPGc+CiAgICA8cGF0aCBmaWxsPSIjRkZGRkZGIiBkPSJNMjguOTQxLDMxLjc4NkwwLjYxMyw2MC4xMTRjLTAuNzg3LDAuNzg3LTAuNzg3LDIuMDYyLDAsMi44NDljMC4zOTMsMC4zOTQsMC45MDksMC41OSwxLjQyNCwwLjU5ICAgYzAuNTE2LDAsMS4wMzEtMC4xOTYsMS40MjQtMC41OWwyOC41NDEtMjguNTQxbDI4LjU0MSwyOC41NDFjMC4zOTQsMC4zOTQsMC45MDksMC41OSwxLjQyNCwwLjU5YzAuNTE1LDAsMS4wMzEtMC4xOTYsMS40MjQtMC41OSAgIGMwLjc4Ny0wLjc4NywwLjc4Ny0yLjA2MiwwLTIuODQ5TDM1LjA2NCwzMS43ODZMNjMuNDEsMy40MzhjMC43ODctMC43ODcsMC43ODctMi4wNjIsMC0yLjg0OWMtMC43ODctMC43ODYtMi4wNjItMC43ODYtMi44NDgsMCAgIEwzMi4wMDMsMjkuMTVMMy40NDEsMC41OWMtMC43ODctMC43ODYtMi4wNjEtMC43ODYtMi44NDgsMGMtMC43ODcsMC43ODctMC43ODcsMi4wNjIsMCwyLjg0OUwyOC45NDEsMzEuNzg2eiIvPgogIDwvZz4KPC9zdmc+Cg==);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        height: 32px;
        position: absolute;
        right: 20px;
        top: 20px;
        width: 32px;
    }
}
</style>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
    transition: opacity 150ms linear;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
